import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-wiki/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Effects`}</h1>
    <h2>{`Overview`}</h2>
    <p>{`Bitwig Studio includes two main types of effects devices:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "audio-fx"
        }}>{`Audio FX`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "note-fx"
        }}>{`Note FX`}</a></li>
    </ul>
    <p><a parentName="p" {...{
        "href": "note-fx"
      }}>{`Note FX`}</a>{` apply to the `}<a parentName="p" {...{
        "href": "midi"
      }}>{`MIDI`}</a>{` notes coming from a single device, while
`}<a parentName="p" {...{
        "href": "audio-fx"
      }}>{`Audio FX`}</a>{` can be applied either at the level of an individual track, or to
multiple tracks by using an `}<a parentName="p" {...{
        "href": "effect-track"
      }}>{`Effect Track`}</a>{`.`}</p>
    <h2>{`Audio FX`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "delay"
        }}>{`Delay`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "reverb"
        }}>{`Reverb`}</a></li>
    </ul>
    <h2>{`Note FX`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "arpeggiator"
        }}>{`Arpeggiator`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      